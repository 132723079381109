.story_width{
    width: 450px;
}

@media screen and (max-width: 700px) {
    .story_width{
        width: 254px;
    }
}

.goUp{
    display: inline-flex;
    opacity: 0;
    transform: translate3d(0, -100px, 0);
    transition: 0.1s ease-in-out;
  }
  .waitDown{
    display: inline-flex;
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }
  .initial{
    display: inline-flex;
    opacity: 1;
    transform: translate3d(0, 0px, 0);
    transition: 0.1s ease-in-out;
  }

.goDown{
    display: inline-flex;
    opacity: 0;
    transform: translate3d(0, 100px, 0);
    transition: 0.1s ease-in-out;
}
.waitUp{
    display: inline-flex;
    opacity: 0;
    transform: translate3d(0, -100px, 0);
}