.flex-none{
    display: flex;
}

.block-none{
    display: block;
}

.none-block{
    display: none;
}

.center{
    justify-content: space-around;
    align-items: center;
}

.mtuserstory{
    margin-top: 256px;
}

.pluserstory{
    padding-left: 64px;
}

@media screen and (max-width: 700px) {
    .flex-none{
        display: none;
    }
    .center{
        justify-content: start !important;
        align-items: start !important;
    }
    .mtuserstory{
        margin-top: 214px !important;
    }
    .pluserstory{
        padding-left: 14px !important;
    }
    .block-none{
        display: none !important;
    }
    .none-block{
        display: block !important;
    }
}