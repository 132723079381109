.block-none{
    display: block;
}

.none-flex{
    display: none;
}

@media screen and (max-width: 700px) {
    .block-none{
        display: none !important;
    }
    .none-flex{
        display: flex !important;
    }
}