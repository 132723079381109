.story_width{
    width: 450px;
}

.story_height{
    height: 308px;
    position: relative;
}

.story_bottom{
    position: absolute;
}

@media screen and (max-width: 700px) {
    .story_width{
        width: 284px;
    }
    .story_height{
        height: auto !important;
    }
    .story_bottom{
        position: initial !important;
    }
}